import React, { useContext, useEffect, useRef, useState } from 'react'
import { AppContext } from '../../common/GlobalContext'
import { toast } from 'react-toastify';


import CONS from '../../common/Constants'

import AppShell from '../../components/AppShell/AppShell'
import PageHeaderComponent from '../../components/PageHeaderComponent/PageHeaderComponent'
import { useHistory } from 'react-router-dom';
import moment from 'moment'
import { data } from 'autoprefixer';

export default function ConfigurarTramoPage() {

    
    const [state, setState] = useContext(AppContext)
    const [destinos, setDestinos] = useState(false)
    const history = useHistory()

    // Obtener origenes desde la API
    useEffect(() => {
        if (!state.origenes) {
            const url = CONS.apiCruce + '/api/Sitios'
            fetch(url).then(res => res.json())
                .then(data => {
                    setState({ ...state, origenes: data });
                    // console.log('origines')
                    // console.log(data)
                })
            
        }
    }, [state, setState])

    // Input para buscar los cruces
    const fecha = useRef()
    const origen = useRef()
    const destino = useRef()

    const actualizarDestinos = () => {
        const destinos = CONS.destinos[origen.current.value.split('-')[1]]
        setDestinos(destinos ?? false)
    }

    const buscarCruce = () => {
        if (!origen.current.value || !destino.current.value || !fecha.current.value) {
            toast.error('Debe rellenar todos los campos para poder realizar la busqueda.')
            return
        }
    
        //! Convertir el valor de destino en un arreglo
        const destinos = destino.current.value.split(',');
    
        //! Crear un array para almacenar los resultados
        let resultados = [];
    
        //! Recorrer el arreglo de destinos
        destinos.forEach((destino) => {
            const busqueda = {
                fecha: fecha.current.value,
                origen: origen.current.value.split('-')[0],
                destino: destino
            }

            //! Guardar cada resultado de busqueda en el array resultados
            resultados.push(busqueda);

            console.log('Busqueda:  ')
            console.log(busqueda)

        });
    
        //! Actualizar el estado con el array de resultados
        setState({ ...state, busqueda: resultados })
    
        //! Almacenar el estado en el almacenamiento local
        localStorage.setItem('estado', JSON.stringify({ ...state, busqueda: resultados }))

        //! Pasa directamente a /seleccionar-cruce 

        history.push('/seleccionar-cruce')
    }

    return (
        <AppShell titulo="Buscar Cruces">
            <div className="mb-6">
                <PageHeaderComponent>
                    <div className="w-full text-center">Seleccione una fecha, el origen y el destino para buscar los cruces.</div>
                </PageHeaderComponent>
            </div>

            <div className="w-11/12 mx-auto">

                {/* Input de Fecha */}
                <div className="w-full block mb-6 relative z-10" >
                    <label htmlFor="fecha" className="block mb-1 uppercase font-bold text-xs text-yellow-600">Fecha</label>
                    <input className="w-full rounded block border-gray-200 shadow" type="date" name="fecha" placeholder="dd-mm-aaaa" ref={fecha} defaultValue={moment().format('YYYY-MM-DD')} />
                </div>

                {/* Select de Origen */}
                <div className="w-full block mb-6" >
                    <label htmlFor="origen" className="block mb-1 uppercase font-bold text-xs text-yellow-600" > Origen </label>
                    <select name="origen" id="origen" className="w-full rounded border-gray-300 shadow" ref={origen} onChange={actualizarDestinos} >
                        <option value="0">Seleccione el origen</option>
                        {state.origenes &&
                            Object.keys(state.origenes).map(item => {
                                return (
                                    <option key={`id-origen-${state.origenes[item].id_sitio}`} value={state.origenes[item].id_sitio + '-' + state.origenes[item].nombre_sitio}>{state.origenes[item].nombre_sitio}</option>
                                )
                            })
                        }
                    </select>
                </div>

                {/* Select de Destino */}
                <div className="w-full block mb-6" >
                    <label htmlFor="fecha" className="block mb-1 uppercase font-bold text-xs text-yellow-600">Destino</label>
                    <select name="destino" id="destino" className="w-full rounded border-gray-300 shadow" ref={destino} disabled={destinos ? false : true}>
                        <option value="0" > Seleccione el destino </option>
                        {destinos &&
                            destinos.map(item => {
                                return (<option key={`destino-tramo-${item.id_tramo}`} value={item.id_sitio}>{item.nombre_sitio}</option>)
                            })
                        }
                    </select>
                </div >

                {/* Buscar! */}
                <div className="w-full block mb-g">
                    <button onClick={buscarCruce} className="w-full rounded py-3 bg-blue-700 text-white border-b-2 border-white-900 shadow" style={{ backgroundColor: "#0684c6" }}> Buscar Cruce </button>
                </div>

            </div>
        </AppShell>
    )
}
